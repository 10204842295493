import get from 'lodash/get'

import en from './en.json'
import fr from './fr.json'
import it from './it.json'
import es from './es.json'
import de from './de.json'
import uk from './uk.json'
import ru from './ru.json'
import ko from './ko.json'
import zh from './zh-CN.json'
import ja from './ja.json'

const locs = {
  en,
  fr,
  de,
  it,
  es,
  uk,
  ru,
  ko,
  zh,
  ja,
}

export default (locName = 'en') => {
  const loc = get(locs, locName, en)

  return {
    t: key => get(loc, key, get(en, key, key)),
  }
}
