export const __ENV__ = process.env.NODE_ENV
export const __PROD__ = __ENV__ === 'production'
export const __DEV__ = !__PROD__
export const __BROWSER__ = typeof window !== 'undefined'

export const __API__ = __PROD__ ? 'https://api.bitsear.ch' : 'http://localhost:3003'

const makeMap = array => array.reduce((acc, v) => ((acc[v] = 1), acc), {})

export const GATES = {
  'ipfs.io': 'https://ipfs.io',
  pinata: 'https://gateway.pinata.cloud',
  cloudflare: 'https://cloudflare-ipfs.com',
  infura: 'https://ipfs.infura.io',
  'dweb.link': 'https://dweb.link',
  sloppyta: 'https://ipfs.sloppyta.co',
  ninetailed: 'https://ninetailed.ninja',
}

export const DEFAULT_GATE = 'ipfs.io'

export const LANGS = ['en', 'fr', 'de', 'es', 'it', 'uk', 'ru', 'zh', 'ja', 'ko']
export const LANGS_MAP = makeMap(LANGS)

export const SEARCH_PARAMS = ['q', 'cat', 'page', 'lang', 'time', 'color', 'mag', 'layout', 'inc']
export const CATS = ['all', 'images', 'books']

// For now lang filters are the same as the translated locales, but could potentially include more
export const LANG_FILTERS = LANGS

export const TIME_FILTERS = ['1h', '1d', '1w', '1m', '1y']
export const SOURCE_FILTERS = ['ens', 'ud-crypto', 'ud-zil', 'arweave']
export const COLOR_FILTERS = ['bw']
export const IT_FILTERS = ['animated', 'transparent']
export const MAG_FILTERS = ['large', 'medium', 'small']
export const LAYOUT_FILTERS = ['square', 'tall', 'wide']
export const INC_FILTERS = ['ud-template']

export const COLOR_MAP = {
  red: '#DC1A1A',
  orange: '#FF9800',
  yellow: '#FFEB3B',
  green: '#4CAF50',
  teal: '#00BCD4',
  blue: '#2196F3',
  navy: '#05215f',
  purple: '#9C27B0',
  pink: '#FF007F',
  white: '#FFFFFF',
  gray: '#9E9E9E',
  brown: '#5D4037',
  black: '#000000',
}

export const COLOR_OPTS = Object.keys(COLOR_MAP).map(value => ({ value, hex: COLOR_MAP[value] }))
