import React from 'react'
import styled from 'styled-components'

const Path = styled.path`
  fill: ${p => p.theme[p.colorName] || p.fallBack};
`

export const IconLogo = () => (
  <svg
    version="1.1"
    width="30"
    viewBox="0 0 594.709 594.708"
    enableBackground="new 0 0 594.709 594.708"
  >
    <g>
      <Path
        colorName="blue"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M113.418,0h367.88c62.372,0,113.41,51.038,113.41,113.41v367.881
		c0,62.38-51.038,113.418-113.41,113.418h-367.88C51.039,594.708,0,543.67,0,481.291V113.41C0,51.038,51.039,0,113.418,0z"
      />
    </g>
    <g>
      <Path
        colorName="aWhite"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M297.937,288.714h0.132l0.132,0.016
		c34.099,0.132,61.722,27.754,61.861,61.853l0.008,0.132v0.124c0,2.843-2.307,5.149-5.164,5.149c-2.827,0-5.142-2.307-5.142-5.149
		c0-28.608-23.211-51.819-51.827-51.819c-2.842,0-5.156-2.314-5.156-5.157S295.094,288.714,297.937,288.714L297.937,288.714z
		 M330.669,278.595l0.388,0.163l0.016,0.008l0.016,0.008c7.998,3.386,15.089,8.162,21.301,14.211l0.925,0.932l0.046,0.046
		l0.257,0.264c24.097,23.848,30.138,61.798,12.394,92.535c-21.114,36.568-67.887,49.103-104.447,27.987
		c-21.068-12.161-34.168-32.849-37.431-55.198h-22.683c-0.039,6.329-5.234,11.486-11.555,11.486h-32.965v106.9h29.929h29.921h4.193
		v-35.69c6.74,10.235,14.529,18.451,23.538,24.493c8.922,6.127,18.458,10.499,28.694,13.123c10.235,2.625,20.656,3.938,31.233,3.845
		c19.165-0.086,36.755-3.146,52.93-9.18c16.191-6.127,30.278-14.964,42.261-26.683c12.075-11.641,21.348-25.812,28.003-42.524
		c6.647-16.711,10.057-35.783,10.149-57.046c-0.093-22.311-3.417-41.91-9.979-58.793c-6.555-16.89-15.749-31.062-27.561-42.431
		c-11.804-11.291-25.72-19.864-41.732-25.634c-16.013-5.692-33.509-8.581-52.495-8.581c-6.911,0-14.521,0.878-22.66,2.718
		c-8.13,1.833-16.269,4.458-24.407,7.96c-8.053,3.502-15.399,7.874-22.14,13.03c-6.647,5.172-11.897,11.027-15.834,17.768V110.998
		h-64.043v215.053h32.965c6.329,0,11.517,5.156,11.555,11.478h22.66c1.351-9.343,4.473-18.561,9.466-27.219
		C253.292,276.157,295.405,262.979,330.669,278.595z"
      />
    </g>
  </svg>
)

export const FullLogo = ({ size = 100 }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    viewBox="0 0 569.76 90.909"
    enableBackground="new 0 0 569.76 90.909"
  >
    <g>
      <Path
        colorName="blue"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0,0.552V52.28h7.522c1.65,0.001,2.999,1.344,3.009,2.99h5.905
    c0.352-2.431,1.163-4.834,2.465-7.09c5.499-9.524,17.685-12.789,27.209-7.29s12.79,17.684,7.291,27.208
    c-5.499,9.525-17.684,12.79-27.208,7.291c-5.489-3.169-8.899-8.559-9.75-14.38h-5.91c-0.011,1.646-1.362,2.989-3.01,2.989H0v25.51
    h14.508l1.018-8.652c1.634,2.48,3.522,4.474,5.706,5.938c2.163,1.485,4.475,2.545,6.956,3.18c2.482,0.638,5.007,0.955,7.573,0.934
    c4.645-0.021,8.909-0.763,12.832-2.226c3.925-1.485,7.34-3.627,10.246-6.47c2.926-2.821,5.175-6.257,6.788-10.309
    c1.611-4.052,2.438-8.676,2.461-13.829c-0.022-5.409-0.828-10.16-2.418-14.253c-1.59-4.095-3.818-7.531-6.682-10.288
    c-2.863-2.737-6.236-4.815-10.117-6.215c-3.883-1.378-8.125-2.079-12.727-2.079c-1.676,0-3.52,0.212-5.494,0.659
    c-1.972,0.444-3.944,1.081-5.918,1.93c-1.951,0.848-3.732,1.909-5.366,3.16c-1.611,1.252-2.884,2.672-3.839,4.306V0.552H0L0,0.552z
    M36.099,43.072h0.035l0.035,0.001c8.882,0.038,16.075,7.232,16.113,16.113l0.001,0.034v0.035c0,0.741-0.601,1.342-1.342,1.342
    S49.6,59.998,49.6,59.256c0-7.454-6.046-13.501-13.501-13.501c-0.741,0-1.342-0.601-1.342-1.342
    C34.757,43.673,35.358,43.072,36.099,43.072L36.099,43.072z M115.428,10.732v16.163H103.72v13.362h11.708v27.107
    c0.021,5.218,0.976,9.565,2.821,13.002c1.844,3.457,4.454,6.024,7.806,7.678c3.372,1.655,7.338,2.397,11.898,2.228
    c1.548-0.042,3.034-0.169,4.433-0.36c1.401-0.19,2.779-0.466,4.158-0.848c1.357-0.382,2.779-0.87,4.263-1.464l-4.326-13.235
    c-1.167,0.573-2.397,1.039-3.712,1.379c-1.293,0.339-2.566,0.53-3.797,0.53c-1.633,0-3.055-0.318-4.263-0.976
    c-1.208-0.658-2.163-1.655-2.842-2.971c-0.678-1.314-1.039-2.97-1.039-4.963V40.258h17.435V26.896h-17.308V9.077L115.428,10.732
    L115.428,10.732z M95.065,26.514v62.995H79.54V26.514H95.065L95.065,26.514z M78.139,9.077c0.021,2.016,0.488,3.692,1.357,5.028
    c0.87,1.357,2.016,2.354,3.394,3.034c1.378,0.678,2.863,0.996,4.412,0.996c1.548,0,3.033-0.318,4.412-0.996
    c1.38-0.68,2.525-1.676,3.394-3.034c0.87-1.336,1.336-3.012,1.357-5.028c-0.021-2.014-0.487-3.69-1.357-5.047
    c-0.869-1.336-2.014-2.333-3.394-3.012C90.335,0.339,88.85,0,87.302,0c-1.548,0-3.034,0.339-4.412,1.018
    c-1.378,0.68-2.524,1.676-3.394,3.012C78.627,5.387,78.16,7.063,78.139,9.077z"
      />
    </g>
    <g>
      <Path
        colorName="fg"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M208.712,34.021c-3.14-2.906-6.66-5.048-10.606-6.405
    c-3.944-1.336-8.208-2.016-12.811-1.995c-3.203-0.042-6.299,0.277-9.291,0.955c-2.969,0.679-5.641,1.718-7.997,3.14
    c-2.375,1.441-4.242,3.266-5.599,5.492c-1.379,2.228-2.058,4.9-2.058,7.976c0.064,3.119,0.785,5.685,2.165,7.677
    c1.357,1.995,3.202,3.564,5.515,4.752c2.312,1.166,4.92,2.1,7.804,2.758c2.886,0.679,5.876,1.251,8.951,1.739
    c2.609,0.403,5.133,0.848,7.594,1.378c2.44,0.51,4.646,1.209,6.618,2.079c1.951,0.892,3.499,2.037,4.645,3.479
    c1.125,1.443,1.676,3.289,1.634,5.536c-0.086,1.974-0.573,3.67-1.443,5.049c-0.869,1.399-2.015,2.545-3.416,3.457
    c-1.399,0.892-2.948,1.59-4.645,2.1s-3.415,0.869-5.154,1.06c-1.74,0.214-3.394,0.298-4.943,0.298c-2.821,0-5.684-0.36-8.548-1.104
    c-2.864-0.742-5.557-1.909-8.06-3.478c-2.523-1.569-4.645-3.606-6.427-6.108l-4.582,4.071c1.867,2.801,4.221,5.133,7.107,7
    c2.863,1.867,6.043,3.267,9.544,4.199c3.5,0.934,7.105,1.4,10.837,1.4c2.249,0,4.539-0.147,6.895-0.487
    c2.375-0.34,4.666-0.913,6.873-1.697c2.205-0.784,4.221-1.866,6.002-3.203c1.781-1.336,3.224-3.012,4.307-5.026
    c1.081-1.995,1.653-4.391,1.76-7.148c0.105-2.905-0.361-5.366-1.378-7.359c-1.019-1.995-2.439-3.627-4.264-4.9
    c-1.802-1.294-3.86-2.312-6.151-3.097c-2.291-0.763-4.666-1.378-7.105-1.846c-2.439-0.466-4.793-0.891-7.062-1.251
    c-1.592-0.276-3.267-0.572-4.985-0.933c-1.739-0.34-3.415-0.764-5.07-1.295c-1.655-0.509-3.139-1.166-4.475-1.972
    c-1.336-0.808-2.418-1.782-3.246-2.97c-0.807-1.188-1.252-2.609-1.315-4.284c-0.043-2.016,0.445-3.733,1.484-5.219
    c1.019-1.463,2.418-2.672,4.222-3.627c1.802-0.955,3.839-1.654,6.129-2.121c2.27-0.445,4.645-0.657,7.126-0.615
    c2.333-0.021,4.582,0.128,6.788,0.466c2.184,0.339,4.347,0.975,6.47,1.953c2.121,0.954,4.221,2.354,6.341,4.2L208.712,34.021
    L208.712,34.021z M514.781,0.552v88.957h6.235V55.02c0.021-3.309,0.552-6.362,1.634-9.163c1.082-2.821,2.609-5.28,4.603-7.403
    c1.995-2.1,4.349-3.753,7.084-4.92c2.737-1.188,5.748-1.783,9.079-1.803c4.708,0.021,8.548,0.954,11.517,2.758
    c2.991,1.802,5.196,4.347,6.618,7.592c1.421,3.246,2.121,7.063,2.1,11.412v36.016h6.109V53.493c0-4.114-0.552-7.868-1.655-11.263
    c-1.082-3.394-2.735-6.299-4.921-8.76c-2.205-2.46-4.942-4.37-8.251-5.706c-3.287-1.314-7.126-1.993-11.517-2.014
    c-2.949,0.021-5.834,0.466-8.655,1.315c-2.798,0.848-5.408,2.184-7.806,4.03c-2.417,1.846-4.475,4.263-6.192,7.254V0.552H514.781
    L514.781,0.552z M497.855,76.528c-1.718,1.697-3.585,3.117-5.622,4.284c-2.035,1.145-4.156,2.016-6.362,2.588
    c-2.228,0.594-4.454,0.892-6.724,0.892c-3.585,0-6.958-0.573-10.117-1.718c-3.14-1.146-5.918-2.822-8.336-5.049
    c-2.419-2.227-4.307-4.964-5.664-8.209c-1.379-3.267-2.079-7-2.1-11.241c0.021-4.242,0.721-7.976,2.1-11.242
    c1.357-3.245,3.245-6.002,5.664-8.208c2.418-2.226,5.196-3.902,8.336-5.048c3.159-1.145,6.532-1.718,10.117-1.718
    c2.27,0,4.517,0.275,6.744,0.806c2.249,0.552,4.391,1.378,6.449,2.482c2.058,1.124,3.923,2.523,5.641,4.22l4.073-3.944
    c-2.142-2.079-4.433-3.818-6.937-5.219c-2.481-1.399-5.068-2.439-7.762-3.139c-2.715-0.7-5.432-1.061-8.209-1.061
    c-4.581,0.021-8.823,0.742-12.727,2.228c-3.902,1.464-7.338,3.585-10.267,6.363c-2.927,2.779-5.196,6.15-6.83,10.096
    c-1.653,3.946-2.481,8.399-2.503,13.384c0.021,4.984,0.892,9.438,2.567,13.383c1.677,3.946,3.988,7.319,6.956,10.097
    c2.971,2.779,6.406,4.9,10.288,6.364c3.883,1.483,8.06,2.205,12.515,2.227c2.777,0,5.494-0.339,8.209-1.019
    c2.693-0.7,5.28-1.738,7.762-3.138c2.504-1.38,4.795-3.141,6.937-5.261L497.855,76.528L497.855,76.528z M425.697,85.691
    c0.021,1.315,0.382,2.375,1.038,3.182c0.68,0.785,1.507,1.315,2.504,1.591c0.997,0.254,1.973,0.254,2.969,0
    c0.997-0.275,1.825-0.806,2.503-1.591c0.658-0.807,1.02-1.866,1.04-3.182c-0.021-1.315-0.382-2.375-1.04-3.182
    c-0.678-0.785-1.506-1.315-2.503-1.591c-0.996-0.254-1.972-0.254-2.969,0c-0.997,0.275-1.824,0.806-2.504,1.591
    C426.079,83.316,425.718,84.375,425.697,85.691L425.697,85.691z M378.229,26.641v62.867h6.235V52.857
    c0.021-2.885,0.552-5.578,1.592-8.103c1.039-2.503,2.503-4.729,4.391-6.638c1.865-1.931,4.071-3.437,6.574-4.54
    c2.523-1.103,5.24-1.675,8.188-1.718c1.846,0,3.69,0.233,5.494,0.7c1.802,0.466,3.541,1.209,5.196,2.227l2.8-5.09
    c-2.1-1.273-4.285-2.228-6.555-2.842c-2.269-0.637-4.582-0.912-6.936-0.849c-2.014,0-4.051,0.212-6.088,0.658
    c-2.035,0.424-4.009,1.125-5.875,2.057c-1.889,0.954-3.584,2.164-5.111,3.67s-2.8,3.329-3.796,5.45l-0.256-11.198H378.229
    L378.229,26.641z M355.065,26.641v13.744c-1.76-3.267-4.009-5.982-6.723-8.124c-2.717-2.142-5.685-3.755-8.951-4.793
    c-3.267-1.061-6.598-1.592-10.033-1.592c-4.412,0.023-8.549,0.786-12.408,2.334c-3.839,1.527-7.233,3.711-10.139,6.553
    c-2.927,2.843-5.217,6.237-6.872,10.181c-1.655,3.925-2.503,8.315-2.523,13.13c0.021,4.813,0.868,9.204,2.523,13.171
    s3.945,7.382,6.872,10.267c2.905,2.863,6.3,5.091,10.139,6.639c3.859,1.57,7.996,2.354,12.408,2.376
    c3.415,0,6.681-0.509,9.842-1.549c3.139-1.038,6.065-2.631,8.761-4.751c2.693-2.121,5.047-4.814,7.104-8.081v13.362h6.236
    c0-10.499,0-20.977,0-31.434c0-10.458,0-20.935,0-31.434H355.065L355.065,26.641z M329.358,31.858
    c4.369,0.021,8.208,0.785,11.475,2.249c3.287,1.483,6.024,3.457,8.208,5.959c2.187,2.503,3.839,5.303,4.922,8.42
    c1.103,3.118,1.655,6.321,1.655,9.652c0,3.308-0.553,6.532-1.655,9.649c-1.083,3.119-2.735,5.918-4.922,8.421
    c-2.184,2.502-4.921,4.477-8.208,5.961c-3.267,1.463-7.105,2.205-11.475,2.247c-3.585-0.021-6.936-0.636-10.032-1.888
    c-3.098-1.251-5.812-3.055-8.166-5.366c-2.355-2.312-4.179-5.089-5.494-8.313c-1.314-3.225-1.994-6.809-2.016-10.775
    c0.021-3.967,0.701-7.551,2.016-10.754c1.315-3.203,3.139-5.959,5.494-8.271c2.354-2.313,5.068-4.073,8.166-5.324
    C322.423,32.494,325.773,31.858,329.358,31.858L329.358,31.858z M253.636,90.4c3.395,0,6.768-0.446,10.14-1.357
    c3.372-0.892,6.533-2.248,9.459-4.031c2.926-1.802,5.431-4.029,7.508-6.702l-4.454-3.562c-1.188,1.569-2.651,2.927-4.37,4.114
    s-3.585,2.186-5.62,2.991c-2.037,0.806-4.137,1.421-6.299,1.823c-2.165,0.404-4.286,0.616-6.365,0.616
    c-3.266,0-6.362-0.53-9.289-1.571c-2.928-1.018-5.557-2.544-7.891-4.537c-2.333-1.995-4.221-4.435-5.683-7.319
    c-1.464-2.884-2.376-6.172-2.716-9.861h56.632c0.468-4.922,0.256-9.291-0.593-13.108c-0.869-3.839-2.27-7.149-4.2-9.948
    c-1.93-2.821-4.263-5.133-7-6.937c-2.715-1.823-5.727-3.181-9.014-4.072c-3.266-0.869-6.681-1.315-10.246-1.315
    c-4.453,0.021-8.611,0.828-12.45,2.418c-3.86,1.59-7.233,3.839-10.16,6.745c-2.906,2.905-5.197,6.32-6.83,10.266
    c-1.632,3.945-2.482,8.293-2.503,13.023c0.021,5.005,0.871,9.502,2.503,13.49c1.633,3.966,3.924,7.36,6.83,10.159
    c2.926,2.8,6.299,4.942,10.16,6.427C245.025,89.636,249.183,90.379,253.636,90.4L253.636,90.4z M228.057,55.53
    c0.36-3.86,1.293-7.275,2.758-10.267c1.485-3.012,3.372-5.536,5.685-7.594c2.333-2.079,4.942-3.648,7.869-4.708
    c2.905-1.083,6.001-1.613,9.268-1.613c5.07,0,9.524,0.955,13.3,2.821c3.797,1.867,6.745,4.603,8.867,8.188
    c2.121,3.585,3.223,7.976,3.287,13.172H228.057z"
      />
    </g>
  </svg>
)
