import React, { useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { LazyBoundary } from 'react-imported-component'
import { Helmet } from 'react-helmet-async'
import { ThemeProvider } from 'styled-components'
import { useCookies } from 'react-cookie'
import { hot } from 'react-hot-loader'
import { GlobalHotKeys } from 'react-hotkeys'
import debounce from 'lodash/debounce'

import getTheme from '../theme'
import { __BROWSER__ } from '../config'

import GlobalStyle from '../styles/global'
import GlobalLoader from './GlobalLoader'
import CatchErrors from './CatchErrors'
import Toasts from './Toasts'

import Home from './Home'

// const Search = lazy(() => import('./Search'))

const globalKeyMap = {
  KONAMI: { sequence: 'up up down down left right left right b a' },
}

export default hot(module)(() => {
  const [{ themeName, lang }, setCookie] = useCookies(['themeName', 'lang'])
  const theme = getTheme(themeName)

  useEffect(() => {
    if (!__BROWSER__) {
      return
    }

    const resize = debounce(() => {
      setCookie('width', window.innerWidth)
    }, 250)

    resize()

    window.addEventListener('resize', resize)
    return () => window.removeEventListener('resize', resize)
  }, [setCookie])

  const globalHandlers = {
    KONAMI: () => setCookie('konami', true),
  }

  return (
    <ThemeProvider theme={theme}>
      <Helmet defaultTitle="Bitsear.ch">
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color={theme.blue} />
        <meta name="msapplication-TileColor" content="#2b5797" />
        <meta name="theme-color" content={theme.aBlack} />

        <meta property="og:description" content="Blockchain search for the masses." />
      </Helmet>

      <GlobalStyle />

      <GlobalHotKeys keyMap={globalKeyMap} handlers={globalHandlers} global />

      <CatchErrors lang={lang}>
        <LazyBoundary fallback={<GlobalLoader />}>
          <>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="*" render={() => <Redirect to="/" />} />
            </Switch>

            <Toasts />
          </>
        </LazyBoundary>
      </CatchErrors>
    </ThemeProvider>
  )
})
