import React from 'react'
import styled from 'styled-components'
import capitalize from 'lodash/capitalize'

import { IconLogo } from './Logo'
import useI18n from '../hooks/useI18n'

const Container = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;
  align-items: center;
  justify-content: center;

  > * + * {
    margin-top: 20px;
  }
`

export default () => {
  const i18n = useI18n()

  return (
    <Container>
      <IconLogo />
      <span>{capitalize(i18n.t('words.loading'))} ...</span>
    </Container>
  )
}
