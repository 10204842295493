import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet-async'
import { down } from 'styled-breakpoints'

import { FullLogo } from './Logo'

const Container = styled.div`
  flex: 1;
  width: 80%;

  ${down('sm')} {
    width: 100%;
  }

  margin: auto;
  padding: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    > * + * {
      margin-top: 10px;
    }
  }

  > * + * {
    margin-top: 50px;
  }
`

export default () => {
  return (
    <>
      <Helmet />

      <Container>
        <FullLogo size={150} />

        <div>
          <span>Is in maintenance mode and will come back better than ever before!</span>
          <span>We apologize for the inconvenience.</span>
        </div>
      </Container>
    </>
  )
}
