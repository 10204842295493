import darken from 'polished/lib/color/darken'
import lighten from 'polished/lib/color/lighten'

export const blue = '#0697ff'
export const red = '#DC1A1A'
export const green = '#5cb85d'
export const orange = '#ff9800'

export const lightGrey = '#ececec'

const aWhite = '#ffffff'
const almostWhite = darken(0.01, aWhite)

const aWhite02 = darken(0.02, 'white')
const aWhite05 = darken(0.05, 'white')
const aWhite1 = darken(0.1, 'white')
const aWhite2 = darken(0.2, 'white')

const aBlack = '#232323'
const aBlack02 = lighten(0.02, aBlack)
const aBlack05 = lighten(0.05, aBlack)
const aBlack1 = lighten(0.1, aBlack)
const aBlack2 = lighten(0.2, aBlack)

const boxShadow = `
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px,
              rgba(0, 0, 0, 0.2) 0px 1px 2px 0px,
              rgba(0, 0, 0, 0.2) 0px 2px 1px -1px;
`

const dark = {
  base: aBlack,

  bg: aBlack05,
  bg02: aBlack02,
  bg05: aBlack05,
  bg1: aBlack1,
  bg2: aBlack2,

  fg: aWhite,
  fg2: aWhite2,

  disabled: {
    bg: aBlack1,
  },

  searchInput: {
    bg: aBlack,
    color: aWhite,
    extra: '',
  },

  searchButton: {
    bg: blue,
  },

  links: {
    default: blue,
    visited: '#a0f',
  },
}

const light = {
  base: aWhite,

  bg: almostWhite,
  bg02: aWhite02,
  bg05: aWhite05,
  bg1: aWhite1,
  bg2: aWhite2,

  fg: aBlack,
  fg2: aBlack2,

  disabled: {
    bg: lightGrey,
    color: aBlack,
  },

  searchInput: {
    bg: aWhite,
    color: aBlack,
    extra: boxShadow,
  },

  searchButton: {
    bg: blue,
    color: aWhite,
  },

  links: {
    default: '#1a0dab',
    visited: '#609',
  },
}

const themes = {
  dark,
  light,
}

const main = {
  red,
  green,
  orange,

  blue,
  aBlack,
  aWhite,

  blueDark: darken(0.07, blue),
  blueLight: lighten(0.07, blue),

  boxShadow,
}

export default (themeName = 'dark') => ({
  name: themeName,

  ...main,
  ...themes[themeName],
  ...themes,

  breakpoints: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
  },
})
