import React from 'react'
import styled from 'styled-components'

import { IconLogo } from './Logo'
import getI18n from '../i18n'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${p => p.theme.bg};
  color: ${p => p.theme.fg};

  height: 100vh;
  padding: 20px;
  max-width: 30rem;
  margin: 0 auto;

  h3 {
    font-size: 20px;
    margin: 20px 0;
  }

  pre {
    font-family: monospace;
    background-color: ${p => p.theme.bg02};
    padding: 10px;
    overflow: auto;
    white-space: pre-wrap;
    max-height: 400px;
  }
`

export default class ErrorBoundary extends React.Component {
  static getDerivedStateFromError(err) {
    return { err }
  }

  componentDidCatch(error, errorInfo) {
    console.log('caught', error, errorInfo)
  }

  render() {
    const err = this.state && this.state.err

    if (err) {
      const i18n = getI18n(this.props.lang)

      return (
        <Container>
          <IconLogo />
          <h3>{i18n.t('errors.default')}</h3>
          <pre>{err.message}</pre>
        </Container>
      )
    }

    return this.props.children
  }
}
