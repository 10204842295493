import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  *, *:after, *:before {
    box-sizing: border-box;
    font: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
  }

  body {
    background-color: ${p => p.theme.bg};
    color: ${p => p.theme.fg};
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  }

  #root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  a {
    cursor: pointer;
    text-decoration: none;
    color: ${p => p.theme.blue};

    &:hover {
      text-decoration: underline;
    }
  }

  ::-webkit-input-placeholder {
    color: ${p => p.theme.fg2};
  }

  ::-moz-placeholder {
    color: ${p => p.theme.fg2};
  }

  :-ms-input-placeholder {
    color: ${p => p.theme.fg2};
  }

  :-moz-placeholder {
    color: ${p => p.theme.fg2};
  }

`
