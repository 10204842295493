import React, { useEffect, useState } from 'react'
import { animated, useTransition } from 'react-spring'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { down } from 'styled-breakpoints'
import get from 'lodash/get'

import { __DEV__, __BROWSER__ } from '../config'

import useI18n from '../hooks/useI18n'

const Container = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 10px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  pointer-events: none;

  ${down('sm')} {
    align-items: center;
  }

  > * + * {
    margin-top: 10px;
  }
`

const Box = styled(animated.div)`
  width: 35ch;

  ${down('sm')} {
    width: 100%;
  }
`

const Content = styled.div`
  position: relative;
  cursor: pointer;
  color: ${p => p.theme.fg};
  background-color: ${p => (p.theme.name === 'dark' ? p.theme.bg1 : p.theme.base)};
  ${p => p.theme.boxShadow};

  pointer-events: all;
  padding: 12px 22px;
  padding-bottom: 17px;
  font-size: 14px;
`

export const Loader = styled(animated.div)`
  position: absolute;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(130deg, #00b4e6, #00f0e0);
  height: 5px;
`

const ANIM_CONFIG = { tension: 125, friction: 20, precision: 0.1 }
const NOTIF_DURATION = 30e3
const CHECK_UPDATE_INTERVAL = 60e3

let id = 0
let notifiedRelease = false

export default React.memo(() => {
  const i18n = useI18n()
  const history = useHistory()

  const [refMap] = useState(() => new WeakMap())
  const [cancelMap] = useState(() => new WeakMap())
  const [items, setItems] = useState([])

  const transitions = useTransition(items, item => item.key, {
    from: { opacity: 0, height: 0, loader: '100%' },
    enter: item => next => next({ opacity: 1, height: refMap.get(item).offsetHeight }),
    leave: item => async (next, cancel) => {
      cancelMap.set(item, cancel)
      await next({ loader: '0%' })
      await next({ opacity: 0 })
      await next({ height: 0 })
    },
    onRest: item => setItems(state => state.filter(i => i.key !== item.key)),
    config: (item, state) =>
      state === 'leave' ? [{ duration: NOTIF_DURATION }, ANIM_CONFIG, ANIM_CONFIG] : ANIM_CONFIG,
  })

  useEffect(() => {
    if (!__BROWSER__ || __DEV__ || notifiedRelease) {
      return
    }

    const int = setInterval(async () => {
      try {
        const { time } = await fetch('/time').then(r => r.json())
        if (time <= get(window, '__INITIAL_STATE__.time')) {
          return
        }

        setItems(state => [
          ...state,
          { key: id++, msg: i18n.t('sentences.newRelease'), reload: true },
        ])

        notifiedRelease = true
        clearInterval(int)
      } catch {}
    }, CHECK_UPDATE_INTERVAL)

    return () => clearInterval(int)
  }, [i18n])

  const onClick = (e, item) => {
    e.stopPropagation()
    if (item.reload) {
      history.go()
    }

    if (cancelMap.has(item)) {
      cancelMap.get(item)()
    }
  }

  return (
    <Container>
      {transitions.map(({ key, item, props: { loader, ...style } }) => (
        <Box key={key} style={style}>
          <Content onClick={e => onClick(e, item)} ref={ref => ref && refMap.set(item, ref)}>
            <p>{item.msg}</p>
            <Loader style={{ right: loader }} />
          </Content>
        </Box>
      ))}
    </Container>
  )
})
